<template>
  <div>
    <v-card flat color="accent rounded-0">
      <v-row>
        <v-col md="2">
          <!-- <box-regular username="Controllers"></box-regular> -->
        </v-col>
      </v-row>
    </v-card>

    <div class="common-container">
      <v-form>
        <v-row
          class="mr-4 mt-4 d-inline-flex justify-space-between align-center"
          style="width: 100%"
        >
          <v-col cols="12" md="2" v-if="locations">
            <v-select
              :items="locations"
              :label="$t(`cycle.location`)"
              v-model="selectedLocation"
              :item-text="`description`"
              :item-value="`id`"
              @change="handleChange"
              dense
              outlined
            ></v-select>
          </v-col>

          <v-col cols="12" md="4" v-if="status">
            <VueCtkDateTimePicker
              label="Select date and time range"
              v-model="date_time"
              color="#9ac31c"
              button-color="#9ac31c"
              format="YYYY-MM-DD HH:MM"
              range
              no-button
              no-shortcuts
              class="mt-n6"
            />
          </v-col>

          <v-col cols="12" md="2" v-if="status">
            <v-select
              :items="aliveData"
              :label="`Killed`"
              v-model="isAlive"
              :item-text="`name`"
              :item-value="`value`"
              @change="handleChange"
              dense
              outlined
              clearable
            ></v-select>
          </v-col>

          <v-col cols="12" md="2"> </v-col>
          <v-col cols="12" md="2"> </v-col>
          <v-col cols="12" md="2" class="d-flex">
            <v-btn class="" color="primary" @click="download">
              {{ $t("download_csv.title") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "@/i18n";

export default {
  name: "download-csv",
  async mounted() {
    await this.fetchLocation();
  },
  data() {
    return {
      selectedLocation: null,
      cycleValue: null,
      date_time: null,
      aliveData: [
        { name: i18n.t("common.true"), value: "True" },
        { name: i18n.t("common.false"), value: "False" },
      ],
      isAlive: null,
      status: [
        { name: i18n.t("cycle.status.positioned"), value: 1 },
        { name: i18n.t("cycle.status.in_transit"), value: 2 },
      ],
      selectedStatus: null,
      params: {},
      headers: [
        {
          text: i18n.t("cycle.table.id"),
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: i18n.t("cycle.table.barcode"), value: "barcode" },
        { text: i18n.t("cycle.table.area"), value: "area_description" },
        { text: i18n.t("cycle.table.location"), value: "location_description" },
        { text: i18n.t("cycle.table.date"), value: "date" },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchLocation: "dashboard/fetchLocation",
      downloadAllCsv: "cycles/downloadAllCsv",
    }),
    createParams() {
      const params = {};
      if (this.date_time) params["date_time_from"] = this.date_time.start;
      if (this.date_time) params["date_time_to"] = this.date_time.end;
      params["location_id"] = this.selectedLocation;
      params["alive"] = this.isAlive;
      this.params = params;
    },
    handleChange() {
      this.createParams();
    },
    download() {
      this.createParams();
      this.downloadAllCsv(this.params);
    },
  },
  computed: {
    ...mapGetters({
      locations: "dashboard/getLocations",
    }),
  },
};
</script>

<style></style>
